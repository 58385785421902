






import { Component, Vue } from 'vue-property-decorator';
import CrollLog from './components/CrollLog.vue';

@Component({
  name: 'CollectionHistory',
  components: {
    CrollLog,
  },
})
export default class extends Vue {
}
